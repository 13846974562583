.btn-primary,
.btn-primary:active,
.btn-primary:visited {
    background-color: #2b882e !important;
    border-color: #2b882e;
}
.btn-primary:hover {
    background-color: #1b561d !important;
    border-color: #1b561d;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
}

.carousel-indicators li {
    background-color: #10994c;
    background-color: rgba(1, 70, 7, 0.25);
}

.carousel-indicators .active {
    background-color: #0c4419;
}

.carousel-control-next-icon {
    filter: invert(1);
}

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important;
}

#about_resume { 
    padding-left:20px;
    padding-right:20px;
    float:right;
}

#highlights {
    overflow-y: scroll;
    height:500px;
    width: auto;
    border-radius: 20px;
    background-image: linear-gradient(#28a74538, #28a74621, rgba(0,255,48,0.02));
}

#highlight_date {
    font-weight: bold;
}

#highlights_list {
    padding-top: 15px;
    padding-right: 15px;
}

#highlights-heading {
    padding-top: 20px;
    padding-left: 15px;
}

#social-media{
    margin-bottom: 5px;
}

#image-div{
    grid-column: 1;grid-row: 1;
}

#image-tag{
    max-width: 100%;height: auto;
}

#button-margin{
    margin: 3px;
}

#subject_style{
    margin-left: 5px;
    margin-bottom: 5px;
}

#carousel_style{
    margin-top: 25px;
}

#experience_img_style{
    max-width: 100%;height: auto;padding-bottom: 10px
}

#skills_heading{
    color: #0c4419
}